<template>
  <div class="eleNet">
    <div class="part1">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="derction">
        依据《关于新形势下配电网高质量发展的指导意见》，配电网数字化转型迫在眉睫。数字化转型可大幅提升配电网的智能化水平和运行效率，实现精准调控，保障电力供应稳定可靠。同时，还能促进新能源接入，推动能源结构优化，支撑绿色低碳发展，满足社会对高质量能源服务的需求，是实现配电网高质量发展的必由之路。<br>
        凭借对电网企业信息化架构和配电网业务的深入理解，结合多年的技术积累和成熟的分析方法，我们能够为电网企业提供系列数智化增值服务，助力客户实现业务转型和效能提升，促进企业向智慧、绿色、开放的新型电力系统转型，实现与客户共赢，推动配电网专业高质量发展。
      </div>
    </div>
    <div class="part2">
      <section-title :titleInfo="firstPartTitleInfo2"></section-title>
      <div class="derction">
        围绕电网公司信息化蓝图规划，平台立足顶层设计，打通PMS、EMS、DMS、用电采集、营销、ERP、GIS等系统壁垒实现配电网设备、运行、检修、故障抢修、工程、优质服务等业务模型统一，综合利用、深度挖掘配电网业务数据，有效支撑配网业务的精益管控，为建设以“清洁低碳、安全可控、灵活高效、智能友好、开放互动”为特征的新型电力系统提供信息支撑。
      </div>
      <div class="centerPic"></div>
      <div class="dec">系统集成案例：配网业务综合管控平台</div>
    </div>
    <div class="part3">
      <section-title :titleInfo="firstPartTitleInfo3"></section-title>
      <div class="derction">
        利用机器人流程自动化(RPA)技术集成多源业务数据，实时监控数据的完整性、一致性和准确性，自动识别并清理数据中的不一致、重复或错误条目，确保数据质量。同时，根据客户定制化需求自动开展数据稽查任务，显著减少人工参与数据治理工作比例，降低人力成本，提升数据质量和工作效率，促进企业数字化转型.
      </div>
      <div class="partCenter">
        <div class="pCLeft">
          <div>
            <div class="pCLTitle">自动化稽查工具带来效益</div>
            <div class="pCLeftdiv">
              1、减少运维人员2名，节省人力成本约15万元/年;
            </div>
            <div class="pCLeftdiv">
              2、周期内数据治理工作时间减少75%，日常工作效率提升3倍;
            </div>
            <div class="pCLeftdiv">
              3、相同自动化标准目标情况下工具智能化推荐改造清单较人工梳理改造清单节省预算80多万元。
            </div>
          </div>
          <div>系统集成案例：配网业务综合管控平台</div>
        </div>
        <div class="pCRight"></div>
      </div>
    </div>
    <div class="part4">
      <section-title :titleInfo="firstPartTitleInfo4"></section-title>
      <div class="dec">技术研究案例：配电网电气计算及动态模拟技术研究</div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo2: {
        bigtext1: "平台简介",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo3: {
        bigtext1: "工具介绍",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo4: {
        bigtext1: "研究内容",
        bigtext2: "",
        smallText:
          "基于PMS拓扑数据的配电网实用化电气计算技术研究:针对新型配电系统中的共性化电气计算需求，开展基于中台的多源基础数据融合、配电网理论计算和实用化计算方法研究、整县分布式光伏接入分析，研究成果具备支撑规划、运检、调度等业务部门的配电网共性分析计算能力。以Web化图形图像处理为基础的配电网多场景动态拟技术研究:针对新型配电系统建设中的可视化分析业务需求，开展以Web化图形图像处理为基础的配电网多场景动态模拟研究，提升涉及新能源的网架项目评审与决策分析效率，支撑新型配电系统的规划建设工作。",
        showKnowMore: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.eleNet {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .part1 {
    width: 100%;
    height: 518px;
    padding: 160px 170px;
    box-sizing: border-box;
    .derction {
      line-height: 32px;
    }
  }
  .part2 {
    width: 100%;
    height: 956px;
    padding: 160px 170px;
    box-sizing: border-box;
    background: url("../images/elecNetpart2Bg.png") no-repeat;
    background-size: 100% 100%;
    .derction {
      line-height: 32px;
    }
    .centerPic {
      width: 100%;
      height: 320px;
      margin-bottom: 110px;
    }
  }
  .part3 {
    width: 100%;
    height: 889px;
    padding: 160px 170px;
    box-sizing: border-box;
    .derction {
      line-height: 32px;
      margin-bottom: 50px;
    }
    .partCenter {
      display: flex;
      width: 100%;
      height: 388px;
      .pCLeft {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: calc(100% - 769px);
        height: 100%;
        .pCLTitle {
          font-weight: bold;
          margin-bottom: 15px;
        }
        .pCLeftdiv {
          margin-bottom: 15px;
        }
      }
      .pCRight {
        width: 769px;
        height: 100%;
        background: url("../images/elecNetpart3Bg.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .part4 {
    width: 100%;
    height: 889px;
    padding: 160px 170px;
    box-sizing: border-box;
    background: url("../images/elecNetpart4Bg.png") no-repeat;
    background-size: 100% 100%;
    .dec {
      margin-top: 292px;
    }
  }
}
</style>