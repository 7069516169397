<template>
  <div class="stationChange">
    <div class="part1">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="derction">
        智能变电站辅助设备监控系统解决方案致力于数字化变电站转型与建设,高度集成视频监控与巡检系统、环境监控系统、智能照明系统、安全防范系统、门禁管理系统、火灾报警、设备温度监测系统、SF6泄露报警系统等辅助控制系统,实现全站的信息共享与互动,基于智能传感、边缘计算、人工智能、大数据等数字化技术,通过监测、预警和控制等手段,为变电站的安全生产提供可靠的保障。
      </div>
    </div>
    <div class="part2">
      <section-title :titleInfo="firstPartTitleInfo2"></section-title>
      <div class="part2Center">
        <div class="centerLeft">
          <div class="centerLeftTop">
            <div class="bord bord1">
              <div class="bordIcon"></div>
              <div class="bordTitle">
                <div class="title1">安全防范</div>
                <div class="title2">与联动控制</div>
              </div>
            </div>
            <div class="bord bord2">
              <div class="bordIcon"></div>
              <div class="bordTitle">
                <div class="title1">状态监测</div>
                <div class="title2">与风险预警</div>
              </div>
            </div>
            <div class="bord bord3">
              <div class="bordIcon"></div>
              <div class="bordTitle">
                <div class="title1">动环监测</div>
                <div class="title2">与联动控制</div>
              </div>
            </div>
            <div class="bord bord4">
              <div class="bordIcon"></div>
              <div class="bordTitle">
                <div class="title1">门禁监控</div>
                <div class="title2">与安全管理</div>
              </div>
            </div>
            <div class="bord bord5">
              <div class="bordIcon"></div>
              <div class="bordTitle">
                <div class="title1">视频巡检</div>
                <div class="title2">与图像识别</div>
              </div>
            </div>
            <div class="bord bord6">
              <div class="bordIcon"></div>
              <div class="bordTitle">
                <div class="title1">智能照明</div>
                <div class="title2">与灯光辅控</div>
              </div>
            </div>
          </div>
          <div class="centerLeftBottom">
            <div class="bottom1">
              <div class="bottomtext1">
                <div class="bottomtext1Icon"></div>
                安全监测
              </div>
              <div class="bottomtext2">
                <div class="bottomtext1Icon"></div>
                视频巡检
              </div>
            </div>
            <div class="bottom1">
              <div class="bottomtext1">
                <div class="bottomtext1Icon"></div>
                漏水监测
              </div>
              <div class="bottomtext2">
                <div class="bottomtext1Icon"></div>
                空调联动
              </div>
            </div>
            <div class="bottom1">
              <div class="bottomtext1">
                <div class="bottomtext1Icon"></div>
                电子防盗
              </div>
              <div class="bottomtext2">
                <div class="bottomtext1Icon"></div>
                红外报警
              </div>
            </div>
            <div class="bottom1">
              <div class="bottomtext1">
                <div class="bottomtext1Icon"></div>
                在线测温
              </div>
              <div class="bottomtext2">
                <div class="bottomtext1Icon"></div>
                自动巡检
              </div>
            </div>
          </div>
        </div>
        <div class="centerRight"></div>
      </div>
    </div>
    <div class="part3">
      <section-title :titleInfo="firstPartTitleInfo3"></section-title>
      <div class="part3Bord">
        <div class="rightDiv">
          <div class="rightDivText">
            <div class="rightDivIcon"></div>
            变“人工式的巡检判断排除”为
            <div class="bold">“数字化智能判断”</div>
          </div>
          <div class="rightDivText">
            <div class="rightDivIcon"></div>
            变“正常的几天或几周巡视”为
            <div class="bold">“24小时实时多维度监控”</div>
          </div>
          <div class="rightDivText">
            <div class="rightDivIcon"></div>
            变“传统的监视为核心”为
            <div class="bold">“智能监视与自动控制为核心”</div>
          </div>
          <div class="rightDivText">
            <div class="rightDivIcon"></div>
            将分散孤立的各
            <div class="bold">子系统进行集成和联动</div>
          </div>
          <div class="rightDivText">
            <div class="rightDivIcon"></div>
            将事故后报警转变为
            <div class="bold">事故前预警控制</div>
          </div>
          <div class="rightDivText">
            <div class="rightDivIcon"></div>
            将分散的巡视检查变为
            <div class="bold">基于云平台的集中监控</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo2: {
        bigtext1: "系统功能",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo3: {
        bigtext1: "系统亮点",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.stationChange {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .part1 {
    width: 100%;
    height: 890px;
    padding: 160px 170px;
    box-sizing: border-box;
    background: url("../images/changeStationPart1.png") no-repeat;
    background-size: 100% 100%;
    // margin-bottom: 30px;
    .derction {
      line-height: 32px;
    }
  }
  .part2 {
    width: 100%;
    height: 950px;
    padding: 160px 170px;
    box-sizing: border-box;
    background-color: #f6f6f6;
    .part2Center {
      display: flex;
      width: 100%;
      height: 480px;
      margin-top: 60px;
      .centerLeft {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 55%;
        height: 100%;
        .centerLeftTop {
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;
          align-items: center;
          width: 754px;
          height: 192px;
          .bord {
            display: flex;
            align-items: center;
            width: 222px;
            height: 84px;
            padding: 0 16px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 12px;
            .bordTitle {
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              height: 52px;
            }
          }
          .bord1 {
            .bordIcon {
              width: 52px;
              height: 52px;
              background: url("../images/changeStationicon1.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 18px;
            }
          }
          .bord2 {
            .bordIcon {
              width: 52px;
              height: 52px;
              background: url("../images/changeStationicon2.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 18px;
            }
          }
          .bord3 {
            .bordIcon {
              width: 52px;
              height: 52px;
              background: url("../images/changeStationicon3.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 18px;
            }
          }
          .bord4 {
            .bordIcon {
              width: 52px;
              height: 52px;
              background: url("../images/changeStationicon4.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 18px;
            }
          }
          .bord5 {
            .bordIcon {
              width: 52px;
              height: 52px;
              background: url("../images/changeStationicon5.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 18px;
            }
          }
          .bord6 {
            .bordIcon {
              width: 52px;
              height: 52px;
              background: url("../images/changeStationicon6.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 18px;
            }
          }
        }
        .centerLeftBottom {
          display: flex;
          width: 100%;
          height: 160px;
          background: url("../images/changeStationBord2.png") no-repeat;
          background-size: 100% 100%;
          .bottom1 {
            height: 100%;
            width: 200px;
            padding: 35px 0 0 40px;
            box-sizing: border-box;
            .bottomtext1 {
              display: flex;
              margin-bottom: 19px;
              align-items: center;
              .bottomtext1Icon {
                width: 14px;
                height: 8px;
                margin-right: 8px;
                background: url("../images/changeStationIcon.png") no-repeat;
                background-size: 100% 100%;
              }
            }
            .bottomtext2 {
              display: flex;
              align-items: center;
              .bottomtext1Icon {
                width: 14px;
                height: 8px;
                margin-right: 8px;
                background: url("../images/changeStationIcon.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
      .centerRight {
        width: 45%;
        height: 100%;
        background: url("../images/changeStationPart2Right.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .part3 {
    width: 100%;
    height: 955px;
    padding: 160px 170px;
    box-sizing: border-box;
    .part3Bord {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 35px;
      box-sizing: border-box;
      width: 1600px;
      height: 590px;
      background: url("../images/changeStationPart3Bg.png") no-repeat;
      background-size: 100% 100%;
      .rightDiv {
        display: flex;
        justify-content: center;
        flex-flow: column;
        width: 850px;
        height: 373px;
        background-color: white;
        padding-left: 30px;
        box-sizing: border-box;
        .rightDivText {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .rightDivIcon {
            width: 14px;
            height: 8px;
            margin-right: 8px;
            background: url("../images/changeStationIcon.png") no-repeat;
            background-size: 100% 100%;
          }
          .bold {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>