<template>
  <div class="smartSup">
    <!-- <section-title :titleInfo="firstPartTitleInfo"></section-title> -->
    <div class="part1">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="derction">
        智慧配电站房可视化系统是智融科技基于微型传感技术、低功耗无线技术、边缘计算技术、人工智能技术等建立“传感单元微型化、设备感知全景化、数据传输无线化、物联控制去中心化、应用分析智能化”的互联体系，使设备状态掌握更加清晰、状态变化趋势更加明确、检修管理策略更为科学，从而提高配电站房运行、巡视、检修、管理的安全性、稳定性，提高运维的工作效率及水平，实现电网管理从被动响应到主动预警的转化，打造“可自愈免维护”数字化配电站房，延长设备使用寿命，减少故障率。
      </div>
    </div>
    <div class="part2">
      <section-title :titleInfo="firstPartTitleInfo2"></section-title>
    </div>
    <div class="part3">
      <section-title :titleInfo="firstPartTitleInfo3"></section-title>
      <div class="bord">
        <div class="showBord">
          <div class="text">
            <div class="textTop">差异化兼容性解决方案</div>
            <div class="textBottom">
              提供满足各类站房因地制宜的数字化配电站房解决方 案，丰富工程经验
            </div>
          </div>
        </div>
        <div class="showBord">
          <div class="text">
            <div class="textTop">无线传感即插即用少维护</div>
            <div class="textBottom">
              基于物联网协议实现无线传感自动识别、自动接入物
              联网关，高可靠，无线化
            </div>
          </div>
        </div>
        <div class="showBord">
          <div class="text">
            <div class="textTop">工业级强算力国产化网关</div>
            <div class="textBottom">
              基于工业级国产化芯片，符合物联网体系架构，支持 AI技术与5G应用
            </div>
          </div>
        </div>
        <div class="showBord">
          <div class="text">
            <div class="textTop">多场景高精准AI模型算法</div>
            <div class="textBottom">
              支持表计识别、人脸识别、安全周界、动物侵入、安
              全违规、声纹AI等检测
            </div>
          </div>
        </div>
        <div class="showBord">
          <div class="text">
            <div class="textTop">本地智能的边缘计算价值</div>
            <div class="textBottom">
              动环就地联动、变压器状态评价、AI分析告警、台区 拓扑辨识等数据应用
            </div>
          </div>
        </div>
        <div class="showBord">
          <div class="text">
            <div class="textTop">融汇电气数据的智能应用</div>
            <div class="textBottom">
              支持以站房为边的整体化边缘计算，汇聚电气、计
              量、环境、状态等的一体化方案
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo2: {
        bigtext1: "核心架构",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo3: {
        bigtext1: "方案亮点",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.smartSup {
  width: 100%;
  height: 100%;
  .part1 {
    width: 100%;
    height: 890px;
    padding: 160px 170px;
    box-sizing: border-box;
    background: url("../images/smartSupbg1.png") no-repeat;
    background-size: 100% 100%;
    .derction {
      line-height: 32px;
    }
  }
  .part2 {
    width: 100%;
    height: 1000px;
    padding: 160px 170px;
    box-sizing: border-box;
    background: url("../images/part2.png") no-repeat;
    background-size: 100% 100%;
  }
  .part3 {
    width: 100%;
    height: 100%;
    padding: 160px 170px;
    box-sizing: border-box;
    .bord {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 555px;
      .showBord {
        width: 32%;
        height: 250px;
        background: url("../images/showBordBg.png") no-repeat;
        background-size: 100% 100%;
        padding-top: 48px;
        box-sizing: border-box;
        .text {
          width: 100%;
          height: 120px;
          .textTop {
            width: 100%;
            height: 30px;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
          }
          .textBottom {
            width: 100%;
            height: 100px;
            padding: 30px;
            line-height: 36px;
            box-sizing: border-box;
            text-align: left;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>