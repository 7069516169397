<template>
  <div class="numParty">
    <div class="part1">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="derction">
        “数智党建”
        PC端与国网江苏省电力公司数据中台，业务中台，技术中台和统一权限平台等基础服务资源集成，统一提供各类党建线上服务和流程管控。
        并且移动端应用结合i国网支撑相关业务和PC端维护的数据，实现外网展示各类党建线上服务和流程管控，构建党建数字化转型成果全景展示窗口。
        产品背景：数字化发展日益融入经济社会发展各领域全过程，网省公司高度重视数字化建设，不断完善推动党建工作数字化、信息化转型，使用移动互联技术充分利用碎片化时间采用多种形式配合开展“两个责任”的具体落实
      </div>
    </div>
    <div class="part2">
      <section-title :titleInfo="firstPartTitleInfo2"></section-title>
      <div class="partCenter">
        <div class="text">核心模块主要分为以下16个</div>
        <div class="imagesBord">
          <div class="imagesOne"></div>
          <div class="imagesTwo">
            <div class="imagesBg"></div>
          </div>
          <div class="imagesThree"></div>
        </div>
      </div>
    </div>
    <div class="part3">
      <section-title :titleInfo="firstPartTitleInfo3"></section-title>
      <div class="showText">
        <div class="showTextLeft">
          <dec-com :tabsList="text1"></dec-com>
          <dec-com :tabsList="text2"></dec-com>
          <dec-com :tabsList="text3"></dec-com>
          <dec-com :tabsList="text4"></dec-com>
          <dec-com :tabsList="text5"></dec-com>
          <dec-com :tabsList="text6"></dec-com>
          <dec-com :tabsList="text7"></dec-com>
          <dec-com :tabsList="text8"></dec-com>
          <dec-com :tabsList="text9"></dec-com>
        </div>
        <div class="showTextRight">
          <dec-com :tabsList="text10"></dec-com>
          <dec-com :tabsList="text11"></dec-com>
          <dec-com :tabsList="text12"></dec-com>
          <dec-com :tabsList="text13"></dec-com>
          <dec-com :tabsList="text14"></dec-com>
          <dec-com :tabsList="text15"></dec-com>
          <dec-com :tabsList="text16"></dec-com>
          <dec-com :tabsList="text17"></dec-com>
          <dec-com :tabsList="text18"></dec-com>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
import DecCom from "./decCom.vue";
export default {
  components: { sectionTitle, DecCom },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo2: {
        bigtext1: "核心模块",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo3: {
        bigtext1: "模块内容",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      text1: {
        title: "首页",
        text1: "主要由轮播图、通知公告和新闻资讯三部分组成",
        text2: "其中新闻资讯部分，基层动态支持基层党员上传简讯并展示的功能",
      },
      text2: {
        title: "组织信息版块",
        text1: "利用大数据分析公司党员信息，多个维度展示公司党员构成",
        text2: "",
      },
      text3: {
        title: "组织信息版块",
        text1: "以组织树的形式展示公司党组织构成，精准查看每一位党员信息",
        text2: "",
      },
      text4: {
        title: "党员信息版块",
        text1: "以组织树的形式展示公司党组织构成，精准查看每一位党员信息",
        text2: "",
      },
      text5: {
        title: "绩效考核版块",
        text1:
          "定期展示党委、职能部室、基层单位绩效考核情况，展示各个党组织的亮点工作",
        text2: "",
      },
      text6: {
        title: "团青信息版块",
        text1: "以组织树的形式展示公司团组织构成，精准查看每一位团员信息",
        text2: "",
      },
      text7: {
        title: "民主评议版块",
        text1: "查看各党组织年度民主评议情况",
        text2: "",
      },
      text8: {
        title: "党内培训版块",
        text1: "掌握党员培训学习情况，内部排名督促学习",
        text2: "",
      },
      text9: {
        title: "三会一课版块",
        text1: "管控党组织三会一课完成情况，对到期未完成的党组织及时告警提醒",
        text2: "",
      },
      text10: {
        title: "党员活动记录",
        text1:
          "党员在特色党建活动现场、急难险重第一线随时随地记录下活动内容、参与人员等信息，",
        text2:
          "党组织书记定期审核，系统数据汇总分析，就可以精准掌握党员参与情况。",
      },
      text11: {
        title: "每月一练版块",
        text1: "以指尖练习的形式督促党员温习党建知识、廉洁知识，时时自省",
        text2: "",
      },
      text12: {
        title: "先锋榜样版块",
        text1: "以图表的形式展示公司榜样先进概况，夯实基础、争取高原",
        text2: "",
      },
      text13: {
        title: "常纪思廉版块",
        text1: "以廉洁知识为主题的资料学习板块。",
        text2: "",
      },
      text14: {
        title: "党员论坛版块",
        text1:
          "让党员之间的交流更加便捷，提高基层党员发表自己建言、看法的积极性",
        text2: "",
      },
      text15: {
        title: "两个清单",
        text1: "责任清单和重点任务清单功能，供相关党员查看自己的任务清单",
        text2: "",
      },
      text16: {
        title: "积分记录",
        text1:
          "普通党员在日常学习过程中予以量化积分，进一步促进党员自我学习自我提升",
        text2: "",
      },
      text17: {
        title: "书记信箱版块",
        text1: "给党员提供了可通过书记信箱，向书记提出党建相关意见和建议的渠道",
        text2: "",
      },
      text18: {
        title: "先锋榜样版块",
        text1: "组织建设：展示党内工作条例等相关规章",
        text2: "党建教育：解读党的精神谱系和习近平新时代中国特色社会主义思想",
        text3: "榜样故事：公司先进模范风采展示",
        text4: "企业文化：公司形势任务、先进典型、安全教育“月”读手册",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.numParty {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .part1 {
    width: 100%;
    height: 518px;
    padding: 160px 170px;
    box-sizing: border-box;
    .derction {
      line-height: 32px;
    }
  }
  .part2 {
    width: 100%;
    height: 1030px;
    padding: 160px 170px;
    box-sizing: border-box;
    background-color: #f6f6f6;
    .partCenter {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      .text {
        margin-bottom: 5px;
      }
      .imagesBord {
        display: flex;
        justify-content: space-between;
        width: 1543px;
        height: 719px;
        .imagesOne {
          width: 361px;
          height: 100%;
          background: url("../images/numPartybg1.png") no-repeat;
          background-size: 100% 100%;
        }
        .imagesTwo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 735px;
          height: 718px;
          background-color: white;
          border-radius: 12px;
          box-shadow: 0px 19 27px 1px rgba(0, 22, 65, 0.08);
          .imagesBg {
            width: 517px;
            height: 478px;
            background: url("../images/numParty2.2.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .imagesThree {
          width: 361px;
          height: 100%;
          background: url("../images/numParty3.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .part3 {
    width: 100%;
    height: 1600x;
    padding: 160px 170px;
    box-sizing: border-box;
    .showText {
      width: 100%;
      display: flex;
      .showTextLeft {
        width: 50%;
      }
      .showTextRight {
        width: 50%;
      }
    }
  }
}
</style>