<template>
  <div class="decCom">
    <div class="title">
      <div class="icon"></div>
      {{ tabsList.title }}
    </div>
    <div class="text1">{{ tabsList.text1 }}</div>
    <div class="text1">{{ tabsList.text2 }}</div>
    <div class="text1">{{ tabsList.text3 }}</div>
    <div class="text2">{{ tabsList.text4 }}</div>
  </div>
</template>

<script>
export default {
  props: {
    tabsList: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.decCom {
  width: 100%;
  margin-bottom: 46px;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-weight: bold;
    .icon {
      width: 14px;
      margin-right: 5px;
      height: 8px;
      background: url("../images/changeStationIcon.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .text1 {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  .text2 {
    padding-left: 20px;
  }
}
</style>