<template>
  <div>
    <Nav></Nav>
    <banner-part :bannerInfo="newBannerInfo"></banner-part>
    <div class="container">
      <tabs-section
        :defaultIndex="defaultIndex"
        :tabsList="tabsList"
      ></tabs-section>
    </div>
    <!-- 配电站房智能辅助 -->
    <smart-sup v-if="defaultIndex == '1'"></smart-sup>
    <!-- 变电站/厂立体巡检系统 -->
    <change-station v-if="defaultIndex == '2'"></change-station>
    <!-- 智慧配电网 -->
    <elec-net v-if="defaultIndex == '3'"></elec-net>
    <!-- 数智党建 -->
    <num-party v-if="defaultIndex == '4'"></num-party>
    <Footer class="top" />
  </div>
</template>

<script>
import BannerPart from "../components/BannerPart.vue";
import TabsSection from "../components/tabsSection.vue";
import ChangeStation from "./components/changeStation.vue";
import ElecNet from "./components/elecNet.vue";
import NumParty from "./components/numParty.vue";
import SmartSup from "./components/smartSup.vue";
export default {
  components: {
    BannerPart,
    TabsSection,
    SmartSup,
    ChangeStation,
    ElecNet,
    NumParty,
  },
  data() {
    return {
      bannerInfo: {
        text1: "配电站房智能辅助系统",
        text2: "做国内一流的智慧能源解决方案及服务提供商",
        swiperImg: require("./images/swiper1.png"),
      },
      defaultIndex: "1",
      tabsList: [
        {
          value: "1",
          label: "配电站房智能辅助",
          path: "/szdl/1",
        },
        {
          value: "2",
          label: "变电站/厂立体巡检系统",
          path: "/szdl/2",
        },
        {
          value: "3",
          label: "智慧配电网",
          path: "/szdl/3",
        },
        {
          value: "4",
          label: "数智党建",
          path: "/szdl/4",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.defaultIndex = this.$route.params.index || '1';
      console.log("this.defaultIndex???SZDL>>>", this.defaultIndex)
    },
  },

  computed:{
    newBannerInfo(){
      let bannerInfo = {}
      if (this.defaultIndex == "1") {
        bannerInfo = {
          text1: "配电站房智能辅助系统",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/swiper1.png"),
        };
      } else if (this.defaultIndex == "2") {
        bannerInfo = {
          text1: "变电站/厂立体巡检系统",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/swiper2.png"),
        };
      } else if (this.defaultIndex == "3") {
        bannerInfo = {
          text1: "智慧配电网",
          text2: "数智化助力配电网产业转型",
          swiperImg: require("./images/swiper3.png"),
        };
      } else if (this.defaultIndex == "4") {
        bannerInfo = {
          text1: "数智党建",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/swiper4.png"),
          blackcolor: true
        };
      }
      return bannerInfo

    }

  },

  mounted() {
    this.defaultIndex = this.$route.params.index || "1";
    console.log("this.defaultIndex>>>>SZDL>>", this.defaultIndex);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>